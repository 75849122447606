<template>
  <div>
    <div class="mb-2">
      <b-button variant="primary-custom" @click="$router.push('/empresas')">
        <fa-icon class="mr-2" icon="arrow-left"></fa-icon>Voltar
      </b-button>
    </div>

    <TableList :data="users" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      unityId: this.$route.params.unityId,
      users: []
    };
  },
  components: {
    TableList: () => import("./components/TableList"),
  },
  computed: {
    bankList() {
      return this.$store.state.unities.bankList;
    },
    bankAccounts() {
      let banks = this.$store.state.unities.bankAccounts;

      _.forEach(banks, (bank) => {
        const filteredBank = _.filter(this.bankList, (b) => {
          return b.id == bank.bank_id;
        });
        bank.bankDetail = filteredBank[0];
      });

      return this.$store.state.unities.bankAccounts;
    },
  },
  methods: {
    async get_users(){
        const response = await this.$store.dispatch('http_get', {
            url: `/user-unity/${this.unityId}`
        })
        this.users = response.data.payload
    }
  },
  async mounted() {
    await this.$store.dispatch("unities/bank_accounts_init", this.unityId);
    this.get_users()
    console.log(this.applicationsProducts);
  },
};
</script>

<style>
</style>